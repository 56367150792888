export const formPage: any = {
  hcpMainMenu: "Main Navigational Menu HCP GCSO - Retina Global",
  patMainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
  hcpFooterMenu: "Footer Navigation Menu HCP GCSO - Retina Global",
  patFooterMenu: "Footer Navigation Menu Consumer GCSO - Global",
  hcpFooter: "Retina Global Footer HCP - US - Global",
  patFooter: "Footer - Consumer - Retina Brazil (PT)",
  dtcExitPopup: "Exit Popup Brazil (PT)",
  hcpExitPopup: "Retina Canada HCP Exit Popup HTML",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  hcpTopNav: "Top Navigational Menu HCP US - Global",
  patTopNav: "Top Navigation Menu Retina Brazil (PT)",
  siteLogo: "Site logos - Retina Brazil (PT)",
  patBanner: "Banner - HTML - Sign up - DTC - Brazil (PT)",
  hcpBanner: "Banner - HTML - Sign up - HCP - Global",
  hcpSignupSucessText: "HCP Sign up Thank you message HTML Global",
  patSignupSucessText: "DTC Sign up Thank you message HTML Brazil (PT)",
  hcpInfoYouprovide: "HCP Sign Up - Information You provide - HTML - Global",
  dtcKnowledgePower: "Knowledge is power - DTC Signup - Brazil (PT)",
  optOutContent: "Sign Up Opt Out DTC - Global",
  hcpRedirectLangUrl: {
    "redirectUrl": {
      "en": "/hcp/sign-up",
      "es": "/es/hcp/sign-up"
    }
  },
  patRedirectLangUrl: {
    "redirectUrl": {
      "en": "/stay-informed",
      "es": "/es/stay-informed"
    }
  },
  backToTopGTMSU: "Back to top - signup hcp",
  backToTopGTMSI: "Back to top - signup",
  footerClassNameDTC: "footer  privacypolicyfooter stayinformedfooter common-footer",
  footerClassNameHCP: "footer  privacypolicyfooter  stayinformedfooterhcp common-footer"
}
