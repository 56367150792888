export enum SignUpFields {
	EmailAddr = "email",
	Province = "province",
	Speciality = "speciality",
	Firstname = "firstname",
	Lastname = "lastname",
	InfoAboutIRDs = "infoAboutIRDs",
	ConsiderationsToGeneticTestingForIRDs = "considerationsToGeneticTestingForIRDs",
	EmergingScienceAroundIRDs = "emergingScienceAroundIRDs",
	NewTreatmentsforIRDs = "newTreatmentsforIRDs",
	IsOver18 = "",
	AgreedTerms = "",
	ZipCode = "zipcode"
}

export enum ErrorMessages {
	requiredError = "This field is required.",
	licenseNoError = "This field is required.",
	generalError = "This field is required.",
	emailError = "Por favor, insira um endereço de e-mail válido",
	provinceError = "Por favor, selecione um estado",
	specialityError = "Please select a specialty",
	firstnameError = "Por favor, insira seu nome",
	lastnameError = "Por favor, insira seu sobrenome",
	checkFieldError = "Please select at least one topic.",
	agreedFieldError = "This field is required.",
	zipcodeError = "Por favor, selecione um estado",
	zipcodeInvalidError = "Por favor, selecione um estado",
	userTypeError = "Por favor, selecione uma opção",
	interestError = "Por favor, selecione uma opção",
	isOver18Error = "Este campo é obrigatório",
	noError = "",
	blankError = " ",
  isOver18= "Este campo é obrigatório"
}

export type ErrorObj = {
	isError: boolean
	field: string
	value: string
	Errormsg: ErrorMessages
}
