import axios from 'axios'

export interface Props {
  email: string;
  firstName: string
  lastName: string
	province: string
	isOver18: boolean
  usertype: string
  notHadGeneticTesting: string
  hadGeneticTesting: string
  hadGeneticTestingAndConfirmed: string
}

/** Rendering template for Patient Sign Up Submission Form
 *
 * @param props props
 * @returns
 */
const PatientSignUpSubmission = async(props: Props) => {
  const { email, province, isOver18, usertype, notHadGeneticTesting, hadGeneticTesting, hadGeneticTestingAndConfirmed, firstName, lastName } = props;

  const leadUrl = `${process.env.PSIGNURL}`

  const bodyFormData = new FormData();

  if (!email || !province) {
    return false
  }

  bodyFormData.append('voce_e', usertype);
  bodyFormData.append('nome', firstName);
  bodyFormData.append('sobrenome', lastName);
  bodyFormData.append('e_mail', email);
  bodyFormData.append('estado', province);
  if (notHadGeneticTesting !== "") {
    bodyFormData.append('voce_ja_fez_algum_teste_genetico_para_identificar_uma_doenca_her', notHadGeneticTesting);
  } else if (hadGeneticTesting !== "") {
    bodyFormData.append('voce_ja_fez_algum_teste_genetico_para_identificar_uma_doenca_her', hadGeneticTesting);
  } else if (hadGeneticTestingAndConfirmed !== "") {
    bodyFormData.append('voce_ja_fez_algum_teste_genetico_para_identificar_uma_doenca_her', hadGeneticTestingAndConfirmed);
  } else {
    // NOOPS
  }
  bodyFormData.append('tenho_18_anos_ou_mais_', isOver18 ? "1" : "0");

  await axios({
    method: "post",
    url: leadUrl,
    data: bodyFormData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
  })
    .then(function () {
      //handle success
      return true
    })
    .catch(function () {
      //handle error
      return false
    });

  return true

}

export default PatientSignUpSubmission
