import React, { useState, useRef, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { SignUpWrapper } from './styles'
import RetinaModal from "@retina-packages/retina-theme-bootstrap/packages/components/RetinaModal"
import PatientSignUpValidation from './Validation/frmValidation'
import { validateFunc } from "./Validation/validateFunc"
import { submitHandller } from "./Submission/submitHandller"
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap/packages'

type PatFormPropType = {
  propdata: any
  location: any
  successPopData: any
  audience: any
  staticMediaFiles: {
    nonSVGImages: any
    svgMediaImages: any
    allMediaDocument: any
  }
  topContentInfo: string
}

const PatientSignUpForm = (props: PatFormPropType) => {

  const [disabled, setDisabled] = useState(true);
  const [showExitModal, setShowExitModal] = useState(false)
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const formField = { 'firstname': '', 'lastname': '', 'email': '', 'province': '', 'isOver18': '', 'usertype': '', 'referencePath': '' }
  const [isOver18Checked, setIsOver18Checked] = useState("")
  const isOver18Ref = useRef(null);
  const [notHadGeneticTesting, setNotHadGeneticTesting] = useState("")
  const [hadGeneticTesting, setHadGeneticTesting] = useState("")
  const [hadGeneticTestingAndConfirmed, setHadGeneticTestingAndConfirmed] = useState("")

  const setField = async (event: any, field: any, value: any) => {
    await validateFunc({ setForm, form, field, value, errors, setErrors, event, setIsOver18Checked, validateField, checkAllField })
  }
  const validateField = async (field: any, value: any) => {
    const fieldValidation = await PatientSignUpValidation({ field, value })
    if (fieldValidation.isError) {
      setErrors({
        ...errors,
        [field]: fieldValidation.Errormsg
      })
    }
    await userTypeValidation(field, value, {
      ...errors,
      [field]: fieldValidation.Errormsg
    })
  }

  const userTypeValidation = async (fld: any, val: any, errs: any) => {
    if (fld === "isOver18" && val === "") {
      const field = "usertype";
      const value = (form["usertype"] === undefined) ? "" : form["usertype"];
      const usrTypeValidation = await PatientSignUpValidation({ field, value })
      if (usrTypeValidation.isError) {
        setErrors({
          ...errs,
          [field]: usrTypeValidation.Errormsg
        })
      }
    }
  }
  const checkAllField = async (inputField: any, inputVal: any) => {
    let newErrors = false;
    for (const key in formField) {
      const field = key;
      let value = validateInputFields({ field, inputField, inputVal, form, key });
      if (field === "isOver18") {
        value = isOver18Ref.current.value
      }


      const fieldValidation = await PatientSignUpValidation({ field, value })
      if (fieldValidation.isError === true) {
        newErrors = true
      }
    }
    newErrors === true ? setDisabled(true) : setDisabled(false);
  }
  const findFormErrors = async () => {
    setDisabled(true)
    const newErrors = []
    for (const key in formField) {
      const field = key;
      let value = form[key] === undefined ? "" : form[key];
      if (field === "isOver18") {
        value = isOver18Ref.current.value
      }
      const fieldValidation = await PatientSignUpValidation({ field, value })
      if (fieldValidation.isError === true) {
        newErrors.push({ "field": [fieldValidation.field], "Errormsg": fieldValidation.Errormsg })
      }
    }
    return newErrors;
  }
  const handleSubmit = async (e: any) => {
    // window.location.href = 'https://mcpmnj7wpncvrphvnpp5hv6443g4.auth.marketingcloudapis.com/v2/authorize?response_type=code&client_id=1mj04pc9bpksihhy89kh34k0&redirect_uri=https%3A%2F%2Fwww.deolhonosgenes.com.br%2Fsaty-informed'
    await submitHandller({ e, findFormErrors, setErrors, setDisabled, form, isOver18Ref, setShowExitModal, notHadGeneticTesting, hadGeneticTesting, hadGeneticTestingAndConfirmed })
  }

  useEffect(()=>{
    // Hide the modal on clicking outside.
    document.querySelector('.modal')?.addEventListener('click', (ev: any) => {
      if (ev.target.classList.contains('modal')) {
        setShowExitModal(false)
      }
    })

    const escapeEvent = (e: any) => {
      // Hide modal on pressing escape key for accessibility.
      if (e.key === "Escape") {
        setShowExitModal(false)
      }
    }
    // Hide modal on pressing escape key for accessibility.
    document.addEventListener('keydown', escapeEvent)
  }, [showExitModal])

  return (
    <>
      <RetinaModal
        location={props.location}
        modalData={{ 'description' : props?.successPopData?.description, 'label': 'patient_sign_up_form_success' }}
        show={showExitModal}
        staticMediaFiles={props.staticMediaFiles}
      />

      <SignUpWrapper className="patient-signup-form">
        <div className="sign-up-title form-para o-column--full">
          <Form className="signup-form" onSubmit={handleSubmit} action="#">
            <Form.Group>
              <HTMLRenderer html={props.topContentInfo} data={props.staticMediaFiles} tagName='div'/>
            </Form.Group>
            <div className="o-container-inner">
              <div className="information-form">
                <div className="form signupform">
                  <div className="apos-area">
                    <p className="required-field-text">Todos os campos são obrigatórios, salvo indicação em contrário.</p>
                    <Form.Group as={'fieldset'} className="checkbox-section optional gtm-form-signup" controlId="validationRadio">
                      <legend className="o-text--normal radio-btn-title">Você é :</legend>
                      <Form.Check className='custom-checkbox-btn custom-radio1' id="patientCheckBox" label="Paciente" name="usertype" type="radio" value="Paciente" isInvalid={!!errors.usertype} onChange={(e: any) => { setField(e, 'usertype', e.target.value); }} aria-required="true" />
                      <Form.Check className='custom-checkbox-btn custom-radio2' id="caregiverCheckBox" label="Cuidador" name="usertype" type="radio" value="Cuidador" isInvalid={!!errors.usertype} onChange={(e: any) => { setField(e, 'usertype', e.target.value); }} aria-required="true" />
                      <Form.Check className='custom-checkbox-btn custom-radio3' id="familiarCheckBox" label="Familiar" name="usertype" type="radio" value="Familiar" isInvalid={!!errors.usertype} onChange={(e: any) => { setField(e, 'usertype', e.target.value); }} aria-required="true" />
                      <Form.Check className='custom-checkbox-btn custom-radio4' id="otherCheckBox" label="Outro" name="usertype" type="radio" value="Outro" isInvalid={!!errors.usertype} onChange={(e: any) => { setField(e, 'usertype', e.target.value); }} aria-required="true" />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.usertype}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="apos-area-widget-wrapperp" controlId="formFirstName">
                      <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Nome </Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control name="firstName" type="text" className='form-field__input' isInvalid={!!errors.firstname} onBlur={(e: any) => setField(e, 'firstname', e.target.value)} aria-required="true" />
                        <Form.Control.Feedback type='invalid' role="alert">
                          {errors.firstname}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="apos-area-widget-wrapperp" controlId="formLastName">
                      <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Sobrenome </Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control name="lastName" type="text" className='form-field__input' isInvalid={!!errors.lastname} onBlur={(e: any) => setField(e, 'lastname', e.target.value)} aria-required="true" />
                        <Form.Control.Feedback type='invalid' role="alert">
                          {errors.lastname}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="validationEmail">
                      <div className="form-field__control gtm-form-signup">
                        <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>E-mail </Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control name="email" type="text" className='form-field__input' isInvalid={!!errors.email} onBlur={(e: any) => setField(e, 'email', e.target.value)} aria-required="true" />
                          <Form.Control.Feedback type='invalid' role="alert">
                            {errors.email}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </div>
                    </Form.Group>

                    <Form.Group controlId="formProvince">
                      <div className="form-field__control gtm-form-signup">
                        <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Estado</Form.Label>
                        <Form.Select size="sm"
                          aria-required="true"
                          className="apos-forms-input form-field__input optional-form-dw optional-dw-patient"
                          isInvalid={!!errors.province}
                          onBlur={(e: any) => setField(e, 'province', e.target.value)}
                          { ... errors.province ? {"aria-invalid": true} : {}}
                          { ... errors.province ? {"aria-describedby": 'province-error'} : {}}
                        >
                          <option value="">Estado</option>
                          <option value="AC">Acre </option>
                          <option value="AL">Alagoas </option>
                          <option value="AP">Amapá </option>
                          <option value="AM">Amazonas </option>
                          <option value="BA">Bahia </option>
                          <option value="CE">Ceará </option>
                          <option value="DF">Distrito Federal </option>
                          <option value="ES">Espírito Santo </option>
                          <option value="GO">Goiás </option>
                          <option value="MA">Maranhão </option>
                          <option value="MT">Mato Grosso </option>
                          <option value="MS">Mato Grosso do Sul </option>
                          <option value="MG">Minas Gerais </option>
                          <option value="PA">Pará </option>
                          <option value="PB">Paraíba </option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco </option>
                          <option value="PI">Piauí </option>
                          <option value="RJ">Rio de Janeiro </option>
                          <option value="RN">Rio Grande do Norte </option>
                          <option value="RS">Rio Grande do Sul </option>
                          <option value="RO">Rondônia </option>
                          <option value="RR">Roraima </option>
                          <option value="SC">Santa Catarina </option>
                          <option value="SP">São Paulo </option>
                          <option value="SE">Sergipe </option>
                          <option value="TO">Tocantins</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid' role="alert" id="province-error">
                          {errors.province}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <p className="o-text--normal radio-btn-title radio-title">Já fez algum teste genético para identificar uma doença hereditária de retina? (Opcional)</p>
                    <Form.Group className="checkbox-section optional gtm-form-signup patient-custom-check" controlId="validationCheckbox1">
                      <Form.Check className='custom-checkbox-btn custom-radio1' label="Nunca fiz/fez testes genéticos" name="expGeneticTesting" type="radio" value="Nunca fiz/fez testes genéticos" onClick={(e: any) => { setNotHadGeneticTesting(e.target.value) }} />
                    </Form.Group>
                    <Form.Group className="checkbox-section optional gtm-form-signup patient-custom-check" controlId="validationCheckbox2">
                      <Form.Check className='custom-checkbox-btn custom-radio2' label="Fiz/fez um teste genético, mas recebi/recebeu um resultado inconclusivo ou negative" name="expGeneticTesting" type="radio" value="Fiz/fez um teste genético, mas recebi/recebeu um resultado inconclusivo ou negative" onClick={(e: any) => { setHadGeneticTesting(e.target.value) }} />
                    </Form.Group>
                    <Form.Group className="checkbox-section optional gtm-form-signup patient-custom-check" controlId="validationCheckbox3">
                      <Form.Check className='custom-checkbox-btn custom-radio3' label="Fiz/fez um teste genético e confirmei/confirmou uma variante genética" name="expGeneticTesting" type="radio" value="Fiz/fez um teste genético e confirmei/confirmou uma variante genética" onClick={(e: any) => { setHadGeneticTestingAndConfirmed(e.target.value) }} />
                    </Form.Group>
                    <hr ></hr>

                    <Form.Group className="custom-check-btn-container check-container-18" controlId="validationCheckbox">
                      <Form.Check ref={isOver18Ref} className='custom-checkbox-btn check-18 check-bold' label="Tenho 18 anos ou mais" name="isOver18" type="checkbox" value={isOver18Checked} id="isOver18" isInvalid={!!errors.isOver18} onClick={(e: any) => setField(e, 'isOver18', e.target.value)} aria-required="true" />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.isOver18}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3" controlId="validationReferencePath">
                    <Form.Control type="text" name="referencePath" onChange={(e: any) => { setField(e, 'referencePath', e.target.value); }} />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className=" o-container o-column-btn-container patient-submit-btn-container">
              <Button className="o-button o-button--primary form-field__submit gtm-form-signup mb-6" type="submit" disabled={disabled} >
              Enviar
              </Button>
            </div>
          </Form>
        </div>
      </SignUpWrapper>
    </>
  )

}

export default PatientSignUpForm;

function validateInputFields(
  { field, inputField, inputVal, form, key }: {
    field: string;
    inputField: any;
    inputVal: any;
    form: any;
    key: string
  }): any {
  if (field === inputField) {
    return inputVal
  } else if (form[field] !== undefined) {
    return form[key]
  } else {
    return ""
  }
}

